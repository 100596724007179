import { Component, createRef, Fragment } from 'preact'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import withStore from '../withStore';
import _ from 'lodash';

import { history } from '../index';
import { actions } from "../actions";
import * as helpers from "@cargo/common/helpers";
import selectors from "../selectors";
import { CRDTState } from '../globals';
import Page from "./page";
import Set from "./set";
import NotificationPage from './notification-page';
import { PinManagerContext } from './pin-context';
import { treeWalker, memoizeWeak } from "../helpers";
import OverlayController from './overlay-controller';

const frontload = async props => { 

	const frontloadPromises = [];

	if(!props.hasContent) {

		const contentFetchPromise = props.fetchContent(props.id, {
			idType: props.idType
		})

		frontloadPromises.push(contentFetchPromise)
	}

	if(helpers.isServer) {
		// do an initial pagination so we return HTML with initial content
		frontloadPromises.push(paginate(props, {up: false, down: true}));
	}

	await Promise.allSettled(frontloadPromises).catch(e => {});

}

// define this here so we don't fail shallow equality tests/
// Inlining contentPad={{top: 0, bottom: 0}} will always cause a re-render
// becase a new object is returned every time.
const defaultContentPad = {top: 0, bottom: 0};
const paginationCount = 10;

class Content extends Component {

	constructor(props) {
		
		super(props);

		this.contentRef = createRef();
		this.contentParentRef = createRef();
		this.topPaginationWatcherRef = createRef();
		this.bottomPaginationWatcherRef = createRef();

		this.paginationLimitsTriggered = {
			up: false,
			down: false
		}

		this.state = {
			contentPad: defaultContentPad,
			pageRenderLimits: {
				// if we're rendering a hash we want to render a cushion of 
				// pages above it as well
				above: this.props.hashPurl ? 25: 0,
				below: 25
			},
			completedPaginationAbove: false,
			completedPaginationBelow: false,

		}

		if(!helpers.isServer) {

			this.paginationObserver = new IntersectionObserver(this.onPaginationObserverCallback, {
				root: document,
				rootMargin: (screen.height * 2) + 'px',
				threshold: [0,1]
			});

			this.resizeObserver = new ResizeObserver((entries)=>{

				entries.forEach((entry)=>{

					var style = window.getComputedStyle(entry.target);
					let top = parseFloat(style.getPropertyValue('margin-top')) +
					parseFloat(style.getPropertyValue('padding-top')) +
					parseFloat(style.getPropertyValue('border-top'));
					let bottom = parseFloat(style.getPropertyValue('margin-top')) +
					parseFloat(style.getPropertyValue('padding-top')) +
					parseFloat(style.getPropertyValue('border-top'));

					if(
						top !== this.state.contentPad.top 
						|| bottom !== this.state.contentPad.bottom
					) {
						// only update state if anything changed. Because we return a new object
						// this setState will always cause a render call
						this.setState({
							contentPad: {
								top: top,
								bottom: bottom,
							}
						});
					}

				});

			});

		}
		
		if(this.props.hasContent) {
			this.props.updateFrontendState({
				activePID: this.props.contentId
			});
		}

	}

	getContentByPosition(position) {
		return this.props.children?.filter(child => child.position === position) || [];
	}

	getPageStack() {

		const pinMarginOffsets = this.getPageContainerPinOffsets();

		// Concat all page content into one big array to avoid re-mounting when pins
		// switch top/bottom positions. Doing this causes Preacts reconciliation algo 
		// to properly recognize the component has only moved and won't unmount/remount
		const pageStack = [];

		// Push top pins to the stack
		this.props.topPins.forEach(pin => {
			pageStack.push(<Page key={pin.id} id={pin.id} contentPad={defaultContentPad} stackedPage={this.props.topPins.length > 1} />)
		});

		this.renderedChildren = [
			// grab the last x items from the pages above the current rendered page
			..._.takeRight(this.getContentByPosition('above'), this.state.pageRenderLimits.above),
			// grab the next x items from the pages below the current rendered page
			..._.take(this.getContentByPosition('below'), this.state.pageRenderLimits.below)
		];

		// Push the content itself
		pageStack.push(
			<div 
				className="pages" 
				ref={this.contentRef}
				style={pinMarginOffsets}
				key="page_container"
			>
			{
				this.props.contentType === "set"
				|| this.props.tag ? 
					(
						!this.props.tag
						// if the site isn't a feed
						&& this.props.isFeed !== true 
						// and the content isn't stacked
						&& this.props.contentIsStack !== true
					) ?
						null
					:
						<Fragment key="page_container_fragment">

							<div 
								className={`pagination-watcher ${this.state.completedPaginationAbove ? 'pagination-complete': ''}`}
								data-direction='up'
								ref={this.topPaginationWatcherRef}
								key="pagination_watcher_up"
							/>

							<Set 
								key={this.props.contentId} 
								id={this.props.contentId} 
								childNodes={this.renderedChildren} 
								contentPad={this.state.contentPad} 
							/>
							
							<div 
								className={`pagination-watcher ${this.state.completedPaginationBelow ? 'pagination-complete': ''}`}
								data-direction='down'
								ref={this.bottomPaginationWatcherRef}
								key="pagination_watcher_down"
							/>

						</Fragment> 
				: 
					// do not render pins or overlays as part of regular content
					this.props.contentIsPin || this.props.contentIsOverlay 
						? 
							null 
						: 
							<Page 
								key={this.props.contentId}
								id={this.props.contentId} 
								contentPad={this.state.contentPad} 
								stackedPage={false}
							/>
			}
			</div>
		)

		// Push the bottom pins
		this.props.bottomPins.forEach(pin => {
			pageStack.push(<Page key={pin.id} id={pin.id} contentPad={defaultContentPad} stackedPage={this.props.bottomPins.length > 1} />)
		})

		return pageStack;

	}

	render() {

		if(this.props.pageNotFound) {
			return <NotificationPage message="Page not found." />;
		}

		if(
			(
				!this.props.hasContent
				|| this.props.contentCRDTState === CRDTState.Deleted
			) && !this.props.tag
		) {
			// no tag and no content. Don't render
			return null;
		}

		const pageStack = this.getPageStack();

		return <div 
			className="content" 
			ref={this.contentParentRef}
		>
			<OverlayController pinContextPID={this.props.parentSetPID} />
			{pageStack}
		</div>

	}

	bindContentParent = ()=>{
		if( this.resizeObserver && this.contentParentRef.current && !this.state.contentParentRef ){
			this.resizeObserver.observe(this.contentParentRef.current)				
			this.setState({
				contentParentRef: this.contentParentRef.current,	
			})
		}
	}

	componentDidMount() {

		this.bindContentParent();
		
		this.attachPaginationWatchers();

		if(this.props.hasContent) {
			this.props.updateFrontendState({
				activePID: this.props.contentId
			});
		}

	}

	shouldComponentUpdate(nextProps, nextState, nextContext) {

		// Don't attempt to render if no props/state/context has changed
		if(
			this.props === nextProps 
			&& this.state === nextState
			&& this.context === nextContext
		) {
			return false;
		}

		// we have to reset page limits before new content is rendered. Otherwise
		// we'll once render new content with old page limits
		if(
			this.props.contentId !== nextProps.contentId
			|| this.props.hashPurl !== nextProps.hashPurl
			|| this.props.tag !== nextProps.tag
		) {

			const renderingNewHash = nextProps.hashPurl && this.props.hashPurl !== nextProps.hashPurl;

			// reset render limits when new content is rendered
			this.setState({
				pageRenderLimits: {
					// if we're rendering a new hash we want to render a cushion of 
					// pages above it as well
					above: renderingNewHash ? 25 : 0,
					below: 25
				}
			});


		}

		return true;

	}

	componentDidUpdate(prevProps, prevState, snapshot) {

		this.bindContentParent();

		if(
			!this.props.adminMode
			&& prevProps.contentCRDTState !== CRDTState.Deleted
			&& this.props.contentCRDTState === CRDTState.Deleted
		) {

			this.props.updateFrontendState({
				alertModal: { message: 'Page not found', type: 'notice' }
			});

			history.replace('/' + prevProps.contentPurl || '/');

		}

		// when we want to generate a new tree
		if(
			this.props.id 
			&& prevProps.id !== this.props.id
		) {

			// get the new page or set that needs rendering
			this.props.fetchContent(this.props.id, {
				idType: this.props.idType
			})

		}

		if(
			this.props.tag 
			&& prevProps.tag !== this.props.tag
		) {

			// not rendering a PID
			this.props.updateFrontendState({
				activePID: undefined
			});

			// get the new tag data
			this.requestPagination();

		}

		if(this.props.hasContent && this.props.contentId !== prevProps.contentId) {
			
			this.props.updateFrontendState({
				activePID: this.props.contentId
			});

			// check if we need to load more content
			this.requestPagination();

		}

		this.attachPaginationWatchers();

		if(this.props.pageNotFound !== prevProps.pageNotFound) {
			this.props.updateFrontendState({
				pageNotFound: this.props.pageNotFound
			});
		}

	}

	componentWillUnmount() {

		if(this.resizeObserver) {
			this.resizeObserver.unobserve(this.contentParentRef.current);
		}

		if(this.paginationObserver) {
			this.paginationObserver.disconnect();
		}

		this.props.updateFrontendState({
			activePID: undefined
		});

	}

	attachPaginationWatchers() {

		if(
			this.paginationObserver
			&& this.topPaginationWatcherRef.current
			&& this.bottomPaginationWatcherRef.current
		) {

			if(this.topPaginationWatcherRef.current !== this.topPaginationWatcherEl) {

				if(this.topPaginationWatcherEl) {
					// If an old pagination watcher exists, unobserve it first
					this.paginationObserver.unobserve(this.topPaginationWatcherEl);
				}

				this.paginationObserver.observe(this.topPaginationWatcherRef.current);
				this.topPaginationWatcherEl = this.topPaginationWatcherRef.current;

			}

			if(this.bottomPaginationWatcherRef.current !== this.bottomPaginationWatcherEl) {

				if(this.bottomPaginationWatcherEl) {
					// If an old pagination watcher exists, unobserve it first
					this.paginationObserver.unobserve(this.bottomPaginationWatcherEl);
				}
				
				this.paginationObserver.observe(this.bottomPaginationWatcherRef.current);
				this.bottomPaginationWatcherEl = this.bottomPaginationWatcherRef.current;

			}

		} else {

			// not mounted anymore
			delete this.topPaginationWatcherEl;
			delete this.bottomPaginationWatcherEl;

		}

	}

	onPaginationObserverCallback = (entries) => {

		let foundIntersection = false;

		entries.forEach(entry => {

			const direction = entry.target.dataset.direction;

			if(entry.isIntersecting) {

				// mark this observer as being within pagination distance
				this.paginationLimitsTriggered[direction] = true;

				// request a pagination call
				foundIntersection = true;

			} else {
				
				// mark this observer as being outside of pagination distance
				this.paginationLimitsTriggered[direction] = false;

			}

		});

		if(foundIntersection) {
			// request a pagination call
			this.requestPagination();
		}

	}

	requestPagination = async () => {

		if(this.isPaginating) {
			return;
		}

		this.isPaginating = true;
		let attempts = 0;

		while(this.isPaginating) {

			if(attempts++ > 25) {
				console.log('Exceeded max consecutive pagination attempts...')
				break;
			}

			// up the page limits
			if(
				this.paginationLimitsTriggered.up
				|| this.paginationLimitsTriggered.down
			) {
				this.setState({
					pageRenderLimits: {
						above: (this.state.pageRenderLimits.above || 0) + (this.paginationLimitsTriggered.up ? paginationCount : 0),
						below: (this.state.pageRenderLimits.below || 0) + (this.paginationLimitsTriggered.down ? paginationCount : 0)
					}
				})
			}

			await paginate(this.props, this.paginationLimitsTriggered).then(paginationRequests => {

				// no pagination requests have been made
				// nothing to render above
				// nothing to render below

				const completedPaginationAbove = paginationRequests.length === 0 && !(this.paginationLimitsTriggered.up && this.state.pageRenderLimits.above < this.getContentByPosition('above').length);
				const completedPaginationBelow = paginationRequests.length === 0 && !(this.paginationLimitsTriggered.down && this.state.pageRenderLimits.below < this.getContentByPosition('below').length);

				this.setState({
					completedPaginationAbove,
					completedPaginationBelow
				});

				if( completedPaginationAbove && completedPaginationBelow) {
					// stop attempting to paginate
					this.isPaginating = false;
				} else {
					// console.log('keep going')
				}

			}).catch(e => {

				console.error(e);

				// Stop pagination if we errored out
				this.isPaginating = false;

			});

			if(this.props.tag) {
				// only one pagination call for tags right now
				this.isPaginating = false;

				// render all
				this.setState({
					pageRenderLimits: {
						above: 9e9,
						below: 9e9
					}
				});
			}

			// wait a beat before continuing
			await new Promise(resolve => setTimeout(resolve, 250));

		}

	}

	getPageContainerPinOffsets = () => {

		const result = {
			'marginTop': 0,
			'marginBottom': 0
		}

		if(helpers.isServer) {
			return result;
		}

		const getTotalMargin = (level) => {
					// if has overlay pins and is adjusting another layer (does not adjust self / needs an 'adjusterId') — then add the height of level
			return (level.hasOverlayPins && !level.adjustsSelf && level.adjusterId !== null ? level.totalHeight : 0 )
				// if has no overlay pins, but has a height (there is an adjust in this case, remove it's height)
			//NOTE: maybe this needs to account for levels that have no overlays, but receive adjust from another level?
						// -(!level.hasOverlayPins && !!level.totalHeight ? level.startingHeight : 0 )
							// if this level has no overlay pins but has a height, remove it
							// - ( !b.hasOverlayPins ? b.startingHeight : 0)
						// if adjusting content, remove the adjust height
						-( level.adjustedLevel === 'content' ? level.adjusterHeight : 0 );
		}

		_.each(this.context.levelData.levels, (level, levelNumber)=>{
			result.marginTop += getTotalMargin(level['top']);
			result.marginBottom += getTotalMargin(level['bottom']);
		})

		// console.log('totals', result.marginTop, result.marginBottom)

		return result
	}

}

const getNestedSets = (setsByParent, setId, results = []) => {

	// add set id
	results.push(setId);

	// find nested sets
	setsByParent[setId]?.forEach(nestedSetId => {
		getNestedSets(setsByParent, nestedSetId, results);
	})

	return results;

}

const paginate = (props, directions, options = {}) => new Promise((resolve, reject) => {

	if(!props || !directions) {
		return reject('Missing pagination parameters');
	}

	if(props.tag) {
		return props.fetchPagesByTag(props.tag.url)
			.then(resolve)
			.catch(reject);
	}

	const state = props.store.getState();
	const results = [];

	// Check out pagination watchers and load content for each one that's in view
	_.each(directions, (requiresPagination, direction) => {

		if(!requiresPagination) {
			return;
		}

		let startParent;
		let startIndex;

		// figure out where to start pagination from
		if(direction === 'up') {

			const item = _.first(props.children);

			if(item) {
				startIndex = item.index;
				
				// find the set to which this index belongs to
				startParent = item.parent;
			}

		} else if(direction === 'down') {

			const item = _.last(props.children);

			if(item) {
				startIndex = item.index;
				
				// find the set to which this index belongs to
				startParent = item.parent;
			} else if(props.contentType === "set") {
				// nothing rendered. Start from the beginning
				startIndex = 0;
				startParent = props.contentId;
			}

		}

		// cannot obtain a starting point, don't run.
		if(startParent === undefined || startIndex === undefined) {
			// console.log('Unable to determine pagination starting point...');
			return;
		}

		// keep track of the amount of results
		let i = 0;

		// only paginate inside the boundaries of the current set
		const setsAllowedToPaginate = getNestedSets(selectors.getSetsByParent(state), props.parentSetPID);

		treeWalker(state.structure, state.pages, state.sets, state.sets.byId[startParent], startIndex, {
			reverse: direction === 'up',
			callback: (parent, index, content) => {

				if(!content && setsAllowedToPaginate.includes(parent)) {
					// fetch this missing content
					results.push({parent, index});

					// we found non-existing content. Up the counter
					i++;
				}

				if(i >= paginationCount) {
					return 'break';
				}

			}
		});

	});

	if(results.length > 0) {

		// group our results by sets
		const resultsByParent = _.groupBy(results, 'parent');

		const promises = _.map(resultsByParent, (items, setId) => {
			return props.fetchContent(setId, {
				indexes: _.map(items, 'index'),
				idType: 'pid'
			})
		});

		Promise.all(promises)
			.then(resolve)
			.catch(reject);

	} else {
		// Nothing to load, resolve the promise.
		resolve([])
	}

});

const getFeedContent = memoizeWeak((structure, pages, sets, startParent, startIndex, setsAllowedToPaginate) => {

	const result = [];
	const contentFound = [];
	const isFeed = sets.byId.root.stack;

	treeWalker(structure, pages, sets, startParent, startIndex - 1, {
		reverse: true,
		traverseUp: true,
		includeSets: true,
		callback: (parent, index, content) => {
			if(!content || !setsAllowedToPaginate.includes(parent)) {
				return 'break'
			} else if(content) {
				// insert at start because we're walking in reverse
				result.unshift({parent, index, content, position: 'above'});
				contentFound.push(content);
			}
		}
	});

	treeWalker(structure, pages, sets, startParent, startIndex, {
		reverse: false,
		traverseUp: true,
		includeSets: true,
		callback: (parent, index, content) => {
			if(!content || !setsAllowedToPaginate.includes(parent)) {
				return 'break'
			} else if(content && !contentFound.includes(content)) {
				result.push({parent, index, content, position: 'below'});
			}
		}
	});

	return result;

});

function mapReduxStateToProps(state, ownProps) { 

	const content = ownProps.idType === 'purl' ?
		selectors.getContentByPurl(state, ownProps.id) 
		: selectors.getContentById(state, ownProps.id);

	let tag;
	let parentSetPID = null;
	let children = null;

	if(!content) {

		// don't set this to true unless we know a loading attempt was made
		let pageNotFound = false;

		if(
			// if we're outside of the admin we'll first try to load a page with this PURL. If that fails
			// we can safely fall back to seeing if the PURL belongs to a tag
			state.frontendState.networkErrors.find(
				error => error.id === ownProps.id && error.idType === ownProps.idType && error
			) !== undefined
			// in the admin we already have all content on the site, so if `content` is empty
			// we can safely fall back to seeing if the PURL belongs to a tag
			|| state.frontendState.inAdminFrame
		) {

			// check to see if the purl is a tag url
			if(ownProps.idType === 'purl') {
				
				// first try to see if the tag is part of the site's tag list
				tag = state.site.tags.find(tag => tag.url === ownProps.id);
				
				// if we're in the admin and the tag wasn't saved to the site model yet, try to pull it from the pages in memory
				if(!tag) {

					for (const pageId in state.pages.byId) {
							
						tag = state.pages.byId[pageId].tags.find(tag => tag.url === ownProps.id);

						if(tag) {
							// found something. Stop the loop
							break;
						}

					}

				}

			}

			if( !tag ) {
				// nothing found.
				pageNotFound = true;
			}

		}

		if(pageNotFound) {
			// don't render anything till we have a root to render from
			return {
				content: null,
				pageNotFound
			};
		}

	}

	if(content) {

		if(content.page_type === "set") { // render a set

			// use the set itself as the starting point for pins to load from
			parentSetPID = content.id;
			
			// when a hash URL has been found, we want to start rendering at the page belonging to that hash's value
			const contentBelongingToHash = selectors.getContentByPurl(state, ownProps.hashPurl);

			// find the index from which we kick off our tree
			let startIndex = state.structure.indexById[contentBelongingToHash?.id] || 0;
			
			// find the set to which this index belongs to
			let startParent = state.sets.byId[selectors.getItemParentId(state, contentBelongingToHash?.id)] || content;

			children = getFeedContent(state.structure, state.pages, state.sets, startParent, startIndex, getNestedSets(selectors.getSetsByParent(state), parentSetPID));

		} else {
			
			// use the page's parent as the starting point for pins to load from
			parentSetPID = selectors.getItemParentId(state, content.id);

		}

	} else if (tag) {

		parentSetPID = 'root';

		children = Object.values(state.pages.byId).filter(page => {

			if(
				state.structure.indexById[page.id] === null
				|| page.crdt_state === CRDTState.Deleted
			) {
				return false;
			}

			return page.tags.some(pageTag => pageTag.tag === tag.tag);

		}).map(page => {

			return {
				content: page,
				position: 'below'
			}

		});

		// Make sure sort order is correct
		children.sort((a,b) => {
			return state.structure.bySort[a.content.id] - state.structure.bySort[b.content.id]
		});

	}

	return {
		tag,
		hasContent: content !== undefined,
		contentId: content?.id,
		contentType: content?.page_type,
		contentTitle: content?.title,
		contentPurl: content?.purl,
		contentIsStack: content?.stack,
		contentIsPin: content?.pin,
		contentIsOverlay: content?.overlay,
		contentCRDTState: content?.crdt_state,
		children,
		parentSetPID,
		isFeed: state.sets.byId.root.stack,
		topPins: selectors.getPinsForSet(state, parentSetPID, 'top', content?.id),
		bottomPins: selectors.getPinsForSet(state, parentSetPID, 'bottom', content?.id),
		adminMode: state.frontendState.adminMode,
	};

}

Content.contextType = PinManagerContext;

function mapDispatchToProps(dispatch) {
	
	return bindActionCreators({
		fetchContent: actions.fetchContent,
		fetchPagesByTag: actions.fetchPagesByTag,
		fetchSitePackage: actions.fetchSitePackage,
		updateFrontendState: actions.updateFrontendState
	}, dispatch);

}

export default withStore(connect(
		mapReduxStateToProps,
		mapDispatchToProps
	)(
		frontloadConnect(frontload, {
			onMount: true,
			onUpdate: false
		})(
			Content
		)
	)
);