import { Component, createRef, isValidElement, Fragment } from "preact";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions } from '../actions';
import _ from 'lodash';
import selectors from '../selectors';
import { PinManagerContext, updateHeights as updatePinContextHeights } from './pin-context';
import PageInfoContextProvider from './page/page-info-context';
import ScrollContextProvider from "./page/scroll-element";
import { CustomElementHost } from './page/register'
import UsesWatcher from './page/uses/uses';
import windowInfo from "./window-info"
import Backdrop from './page/backdrop/index'
import embeds from './page/embeds';
import * as helpers from "@cargo/common/helpers";
import Password from './page/password';
import EditPageButton from './overlay/edit-page-button';
import { getMobileOffsetsString, shallowEqual, memoizeWeak } from "../helpers";
import withStore from '../withStore';

const pageMap = new WeakMap();
const bodycopyMap = new WeakMap();
const pageContentMap = new WeakMap();
const pageLayoutMap = new WeakMap();

let resizeObserver;
let embedObserver;
let aboveViewportObserver;
let viewportBoundaryObserver;
let currentIsolatedStylesheetCSS = null;

const onAboveViewPortCallback = entries => {
	entries.forEach(function(entry){
		if( pageMap.has(entry.target) ){
			pageMap.get(entry.target).onAboveViewPort(entry)
		}
	});
}


const getIsolatedStyleNode = _.once(() => {
	const doc = document.implementation.createDocument('http://www.w3.org/1999/xhtml', 'body', null);
	const style = document.createElement('style');
	doc.children[0].appendChild(style);
	return style;
});


const getIsolatedStyleSheet = css => {

	const style = getIsolatedStyleNode();

	if(css !== currentIsolatedStylesheetCSS) {
		// update style contents only if we encounter new CSS
		style.innerHTML = css;
		currentIsolatedStylesheetCSS = css;
	}

	return style;

};

if(!helpers.isServer) {

	resizeObserver = new ResizeObserver(function(entries){

		const pinContextHeights = {};

		// flush above viewport records before handling resizes
		onAboveViewPortCallback(aboveViewportObserver.takeRecords())

		entries.forEach(function(entry){

			const pageComponent = pageMap.get(entry.target);

			if( pageComponent ){

				// use getBoundingClientRect as entry.contentRect can sometimes
				// be out of date by the time we get this callback, causing Safari
				// to jump when resizing content above the viewport
				pageComponent.onResize(entry.target.getBoundingClientRect())

				// set pin page height using entry.contentRect as it excludes padding
				// whereas getBoundingClientRect includes it in the height
				pinContextHeights[pageComponent.props.id] = entry.contentRect.height;

			}

			if( bodycopyMap.has(entry.target) ){
				bodycopyMap.get(entry.target).onBodyCopyResize(entry.contentRect.width)
			}

			if( pageContentMap.has(entry.target) || pageLayoutMap.has(entry.target) ){

				var style = window.getComputedStyle(entry.target);

				let top = parseFloat(style.getPropertyValue('margin-top')) +
					parseFloat(style.getPropertyValue('padding-top')) +
					parseFloat(style.getPropertyValue('border-top'));

				let bottom = parseFloat(style.getPropertyValue('margin-bottom')) +
					parseFloat(style.getPropertyValue('padding-bottom')) +
					parseFloat(style.getPropertyValue('border-bottom'));

				if( pageContentMap.has(entry.target) ){

					const pageComponent = pageContentMap.get(entry.target);

					if(
						pageComponent.state.pageContentPad.top !== top
						|| pageComponent.state.pageContentPad.bottom !== bottom
					) {

						pageComponent.setState({
							pageContentPad: {
								top, bottom
							}
						})

					}

				} else {

					const pageComponent = pageLayoutMap.get(entry.target);

					if(
						pageComponent.state.pageLayoutPad.top !== top
						|| pageComponent.state.pageLayoutPad.bottom !== bottom
					) {
						
						pageComponent.setState({
							pageLayoutPad: {
								top, bottom
							}
						})

					}
				}

			}

		});

		// update all heights at once
		updatePinContextHeights(pinContextHeights);

	});

	aboveViewportObserver = new IntersectionObserver(onAboveViewPortCallback, {
		root: document,
		rootMargin: '0px 0px 0px 0px',
		threshold: [0,1]
	});

	viewportBoundaryObserver = new IntersectionObserver((entries, o) => {

		entries.forEach(function(entry){
			if( pageMap.has(entry.target) ){
				pageMap.get(entry.target).onViewportBoundaryIntersection(entry)
			}
		});

	}, {
		root: document,
		rootMargin: Math.max(screen.height * 6, 2500) + 'px',
		threshold: [0, 1]
	});

	embedObserver = new MutationObserver(function(changes){

		_.each(changes, function(mutation){

			if (mutation.type == 'childList') {
				
				mutation.addedNodes.forEach(function(node){
					
					if(node.nodeName === "CARGO-EMBED") {
						embeds.add(node);
					}

				});

			}
		});

	});

}

class Page extends Component {

	constructor(props) {
		
		super(props);

		this.overlayContentRef = createRef();

		this.bodycopyRef = createRef();
		this.pageContentRef = createRef();
		this.pageRef = createRef();
		this.pageLayoutRef = createRef();

		this.lastBodycopyRef = createRef();
		this.lastPageContentRef = createRef();
		this.lastPageRef = createRef();
		this.lastPageLayoutRef = createRef();

		this.lastHeight = 0;
		this.state = {
			resizeParentWidth: '100%',
			mobileOffsetsString: null,
			pageContentPad: {
				top: 0,
				bottom:0,
			},
			pageLayoutPad: {
				top: 0,
				bottom: 0,
			},
			windowHeight: null,
			fullyRender: true,
			lastPageContainerHeight: 100,
			overlayContentFromTransformValue: null,
			overlayContentToTransformValue: null,
		}

		this.localStyleRef = createRef();

	}

	setOverlayContentTransformValues = () => {

		const overlayRect = this.overlayContentRef.current?.getBoundingClientRect();
		if (!overlayRect) return;

		const contentRect = this.pageContentRef.current?.getBoundingClientRect();
		if (!contentRect) return;

		let fromValue = 0;
		let toValue = 0;

		let fromPercent = this.props.overlayOptions?.animateOnOpen?.slideAmount / 100;
		let toPercent = this.props.overlayOptions?.animateOnClose?.slideAmount / 100;

		if (this.props.overlayOptions?.animateOnOpen?.speed !== 0) {
			switch(this.props.overlayOptions?.animateOnOpen?.slideDirection) {
				case 'bottom':
					fromValue = (contentRect.height + (overlayRect.height - contentRect.bottom)) * fromPercent;
					break;
				case 'top':
					fromValue = -contentRect.bottom * fromPercent;
					break;
				case 'right':
					fromValue = (contentRect.width + (overlayRect.width - contentRect.right)) * fromPercent;
					break;
				case 'left':
					fromValue = -contentRect.right * fromPercent;
					break;
			}
		}

		if (this.props.overlayOptions?.animateOnClose?.speed !== 0) {
			switch(this.props.overlayOptions?.animateOnClose?.slideDirection) {
				case 'bottom':
					toValue = (contentRect.height + (overlayRect.height - contentRect.bottom)) * toPercent;
					break;
				case 'top':
					toValue = -contentRect.bottom * toPercent;
					break;
				case 'right':
					toValue = (contentRect.width + (overlayRect.width - contentRect.right)) * toPercent;
					break;
				case 'left':
					toValue = -contentRect.right * toPercent;
					break;
			}
		}

		if (this.state.overlayContentFromTransformValue !== fromValue && this.state.overlayContentToTransformValue !== toValue) {
			this.setState({
				overlayContentFromTransformValue: fromValue,
				overlayContentToTransformValue: toValue
			})
			return;
		}
		
		if (
			this.state.overlayContentFromTransformValue !== fromValue
		) {
			this.setState({
				overlayContentFromTransformValue: fromValue
			})
			return;
		}

		if (
			this.state.overlayContentToTransformValue !== toValue
		) {
			this.setState({
				overlayContentToTransformValue: toValue
			})
			return;
		}
	}

	setMobileOffsets = () => {

		// if not mobile, clear the styles
		if (!this.props.isMobile) {
			this.setState({mobileOffsetsString: null})
			return;
		}

		const denyList = [
			'ul',
			'ol',
			'li',
			'h1',
			'h2',
			'h3',
			'h4',
			'h5',
			'h6',
			'sub',
			'sup',
			'small-caps'
		]

		// if no local stylesheet, then we can't calculate offsets
		if(!this.localStyleRef.current) return;

		const globalStyleSheet = getIsolatedStyleSheet(this.props.globalStylesheet);
		let hasZeroPageLayoutPadding = false;
		
		if (globalStyleSheet.sheet) {
			_.each(globalStyleSheet.sheet.rules, rule => {
				if(rule?.selectorText === '.mobile [id] .page-layout') {
					let hasZeroPadding = rule?.style?.getPropertyValue('padding') === '0px';
					if (hasZeroPadding) {
						hasZeroPageLayoutPadding = true;
					}
				}
			});
		}

		if (hasZeroPageLayoutPadding) {
			denyList.push(`[id="${this.props.pid}"] .page-layout`);
		}

		const string = getMobileOffsetsString(this.localStyleRef.current.sheet, [
			{
				properties: [
					//'padding',
					'padding-top',
					'padding-right',
					'padding-bottom',
					'padding-left',
				],
				denyList: denyList
			}
		])

		this.setState({mobileOffsetsString: string})
	}

	onBodyCopyResize = (width) => {

		width = parseInt(width);

		if(this.lastWidth !== width) {

			this.setState({
				resizeParentWidth: width + 'px'
			})

			this.lastWidth = width;
		}

	}

	onWindowResize = (force)=>{

		this.setState(prevState=>{
			if( prevState.windowHeight === windowInfo.data.window.h ){
				return null;
			}
			return {
				windowHeight: windowInfo.data.window.h
			}
		})
	}

	onResize = (dimensions) => {

		if(!dimensions) {
			return;
		}

		let aboveViewportHeightCompensationRequired = 0;

		if(this.isAboveViewport) {

			if(this.lastHeight !== dimensions.height) {
				aboveViewportHeightCompensationRequired += dimensions.height - this.lastHeight;
			}

		}

		this.lastHeight = dimensions.height;

		if(aboveViewportHeightCompensationRequired !== 0) { 

			const targetScrollTop = document.scrollingElement.scrollTop + aboveViewportHeightCompensationRequired;

			document.scrollingElement.scrollTo({
				top: targetScrollTop,
				// ignore scroll-behavior: smooth 
				behavior: 'instant'
			});

		}

	}

	onAboveViewPort = (intersection) => {

		// ignore intersection results when the root bounds height is still zero
		if(intersection.rootBounds.height === 0) {
			return;
		}

		this.isAboveViewport = intersection.boundingClientRect.bottom < 0;

	}

	onViewportBoundaryIntersection = (intersection) => {

		// ignore intersection results when the root bounds height is still zero
		if(intersection.rootBounds.height === 0) {
			return;
		}

		// by default the page should only render when not way outside 
		let shouldFullyRender = intersection.isIntersecting;

		// There are a few scenarios in which pages cannot be hidden
		if(
			// always render pages on the server
			helpers.isServer
			// always render pins
			|| this.props.isPin
			// always render a page being edited
			|| this.props.isEditingPage
		) {
			// force render
			shouldFullyRender = true;
		}

		this.setFullRenderState(shouldFullyRender);

	}

	setFullRenderState = (shouldFullyRender) => {

		const newState = {
			fullyRender: shouldFullyRender,
			// when rendering sparsely, add the height div to 
			// keep the page the same height as before
			renderSparseHeightDiv: !shouldFullyRender
		}

		if(shouldFullyRender) {

			// only run this if we are currently sparsely rendered
			if(this.state.fullyRender !== true) {

				// when going from a sparse to full render, we keep the
				// height div in place temporarily to prevent scroll jumps while 
				// the full page content is rendering. Galleries sometimes
				// start at 0px height and only receive their final height
				// after a frame or so. If we don't keep the sparse height 
				// div in place the page will jump
				newState.renderSparseHeightDiv = true;

				setTimeout(() => {
					// if after 100ms we're still in full render mode
					// we can take away the height div
					if(this.state.fullyRender === true) {
						this.setState({
							renderSparseHeightDiv: false
						})
					}
				}, 100);

			}

		} else {

			// before going into sparse mode, calculate the height of the page while it's fully rendered
			newState.lastPageContainerHeight = this.pageRef.current?.getBoundingClientRect().height || 100

		}

		this.setState(newState);

	}

	updateAdminState = (payload) => {
		this.props.store.dispatch({
			type: 'UPDATE_ADMIN_STATE', 
			payload: payload
		});
	}

	zIndexLayerPosition = (value) => {
		return value?.toString().padStart(2, '0');
	}

	findPageAdjusteeStatus = (pageID) => {

		let adjusteeData = {top: null, bottom: null}

		let adjustees = _.pickBy(this.context?.adjustPairs, (key) => { 
			return key.adjusts.indexOf(this.props.pid) !== -1; 
		});

		if (_.keys(adjustees).length > 0) {
			_.each(adjustees, function(adjustee){
				adjusteeData[adjustee.location]	= adjustee.adjustedHeight;
			})
		}

		return adjusteeData;
	}

	determinePinCSS = () => {

		if(helpers.isServer || this.props.isOverlay) {
			return;
		}

		if (_.isEmpty(this.context.levelData)) return;

		let startZIndexLayer = 1,
			pinCSS = {},
			isPinned = this.context?.renderedPins && this.props.isPin,
			adjustData = this.findPageAdjusteeStatus(this.props.pid);

		// ADJUSTED PADDING
		pinCSS['paddingTop'] = adjustData['top'];
		pinCSS['paddingBottom'] = adjustData['bottom'];

		if (isPinned) {
			let pinData = this.context?.renderedPins[this.props.pid];

			if (pinData) {
				// Z-INDEX
				// going top>down, so deepest level is the highest index
				let pageDepth = pinData.depth,
					deepestDepth = _.keys(this.context.levelData?.levels)?.length+startZIndexLayer,
					layeringIndex = deepestDepth - pageDepth+startZIndexLayer,
					zIndex = layeringIndex + this.zIndexLayerPosition(100-(pinData?.pinSort));
				pinCSS['zIndex'] = pinData.type !== 'adjust' ? zIndex ?? null : null;

				if (localStorage.getItem('pin_testing') === 'true') {
					let pinLogData = {
						old_zIndex: layeringIndex + this.zIndexLayerPosition(100-(pinData?.sort)),
						new_zIndex: zIndex,
						pid: this.props.pid,
						pageList_sort: pinData?.sort, 
						renderedPageSort: pinData?.pinSort,
					}
					console.log(pinLogData);
				}
				

				let pageLevelLocation = this.context.levelData?.levels[pageDepth][pinData.location];

				if (pinData.type === 'overlay') {
					// TOP/BOTTOM value
					pinCSS[pinData.location] = pageLevelLocation.startingHeight - adjustData[pinData.location];

				} else if (pinData.type === 'adjust') {
					// if an adjust only page
					if (adjustData[pinData.location] !== null) {
						// if receiving adjust, use the start height
						pinCSS[pinData.location] = !pageLevelLocation.adjustsSelf ? pageLevelLocation.startingHeight - adjustData[pinData.location] : null
					} else {

						// if not receiving adjust, use the start height + sum of pages in sort below it (bottom = above it)
						//pinCSS[pinData.location] = 
							//pageLevelLocation.startingHeight;
							// + pageLevelLocation.adjusterHeight;
							// + this.getAdjustOnlyPinStartHeight(pageDepth, pinData?.sort, pinData.location);
					}
					
				}
				
			}

		}

		pinCSS['--pin-padding-top'] = (pinCSS['paddingTop'] || 0)+'px';
		pinCSS['--pin-padding-bottom'] = (pinCSS['paddingBottom'] || 0)+'px';

		// return all the CSS as an object
		return !_.isEmpty(pinCSS) ? pinCSS : null ;

	}

	getAdjustOnlyPinStartHeight = (depth, sort, location) => {

		let priorHeights = 0

		_.each(this.context.renderedPins, (pin, id)=> {
			if ( pin.depth === depth 
				&& pin.location === location
				&& (location === 'top' ? pin.sort < sort : pin.sort > sort)
				&& pin.type === 'adjust'
			) {
				priorHeights += this.context.pageHeights[id];
			}
		})

		return priorHeights;
	}

	determinePinClassList(pinOptions) {

		if (!pinOptions || this.props.isOverlay) return '';

		let classList = '';
		classList += ' pinned pinned-'+pinOptions.position;
		classList += pinOptions.fixed ? ' fixed' : pinOptions.overlay ? ' overlay' :  ''

		return classList;

	}

	editPage = (e) => {

		e.preventDefault()


		let hasUnsavedCommerceChanges = parent.window.Cargo?.Ecommerce?.View?.currentView?.saveButton?.saveEnabled === true || parent.window.Cargo?.Ecommerce?.View?.currentView?.SaveButton?.saveEnabled === true;

		if( hasUnsavedCommerceChanges ){
			// If there are unsaved commerce changes, we need to prompt the user to save before closing the window.
			// This event sends the page id to the c2-c3 view controller, which will navigate to the page after the save modal is cleared.
			parent.window.dispatchEvent(
				new CustomEvent('navigate-after-commerce-save', {
					detail: { pid: this.props.pid }
				})
			);
			return
		}


		// e.stopPropagation();
		parent.editorActivationEvent = e;
		parent.navigateAdmin('/' + this.props.pid)

		this.updateAdminState({'pageTitleBarTransition': true})
		setTimeout(()=>{
			this.updateAdminState({'pageTitleBarTransition': false})
		}, 600)

	}

	getPageInfoContextValue() {

		if(
			this.props.isEditingPage !== this.lastProps?.isEditingPage
			|| this.props.pid !== this.lastProps?.pid
		) {

			// only generate new context if needed
			this.pageInfoContextValue = {
				isEditing: this.props.isEditingPage,
				pid: this.props.pid
			}

		}

		this.lastProps = this.props;

		return this.pageInfoContextValue

	}

	render() {

		if(!this.props.hasPage) {
			return null;
		}

		if(helpers.isServer && this.props.access_level !== 'public') {
			// do not server render non-public content
			return null;
		}


		const hasBackdrop = this.props.backdrops?.activeBackdrop && this.props.backdrops?.activeBackdrop != 'none',
			  isPinned = this.props.isPin && this.props.pin_options,
			  pinOptions = isPinned ? this.props.pin_options : null;

		let pageJSX = null,
			pageContentJSX = null,
			pinRelatedCSS = this.determinePinCSS(),
			pinClassList = this.determinePinClassList(pinOptions);

		let layeringRelatedCSS = {};
		// If the --page-layout-mix is set, we need to set the --page-content-backdrop-filter to none
		// so that the backdrop filter doesn't apply to the page content
		// Check if --page-content-mix is set in the local stylesheet
		if (this.props?.local_css?.includes('--page-layout-mix:') && !this.props?.local_css?.includes('--page-layout-mix: normal')) {
			layeringRelatedCSS['--page-content-backdrop-filter'] = 'none';
		} else {
			layeringRelatedCSS['--page-content-backdrop-filter'] = '';
		}

		let overlayRelatedCSS = {};
		let overlayRelatedClasses = '';

		if (this.props.isOverlay === true) {

			let overlayOpenContentFromTransform = 'translate3d(0, 0, 0)';
			switch(this.props.overlayOptions?.animateOnOpen?.slideDirection) {
				case 'bottom':
					overlayOpenContentFromTransform = `translate3d(0, ${this.state.overlayContentFromTransformValue}px, 0)`;
					break;
				case 'top':
					overlayOpenContentFromTransform = `translate3d(0, ${this.state.overlayContentFromTransformValue}px, 0)`;
					break;
				case 'right':
					overlayOpenContentFromTransform = `translate3d(${this.state.overlayContentFromTransformValue}px, 0, 0)`;
					break;
				case 'left':
					overlayOpenContentFromTransform = `translate3d(${this.state.overlayContentFromTransformValue}px, 0, 0)`;
					break;
			}

			if (this.props.overlayOptions?.animateOnOpen?.scaleAmount) {
				const difference = Number(this.props.overlayOptions?.animateOnOpen?.scaleAmount)/100;
				const scaleAmount = 1 - difference;
				overlayOpenContentFromTransform += ` scale(${scaleAmount})`;
			}

			if (this.props.overlayOptions?.animateOnOpen?.rotateAmount) {
				overlayOpenContentFromTransform += ` rotate(${Number(this.props.overlayOptions?.animateOnOpen?.rotateAmount)*-1}deg)`;
			}

			let overlayCloseContentToTransform = 'translate3d(0, 0, 0)';
			switch(this.props.overlayOptions?.animateOnClose?.slideDirection) {
				case 'top':
					overlayCloseContentToTransform = `translate3d(0, ${this.state.overlayContentToTransformValue}px, 0)`;
					break;
				case 'bottom':
					overlayCloseContentToTransform = `translate3d(0, ${this.state.overlayContentToTransformValue}px, 0)`;
					break;
				case 'left':
					overlayCloseContentToTransform = `translate3d(${this.state.overlayContentToTransformValue}px, 0, 0)`;
					break;
				case 'right':
					overlayCloseContentToTransform = `translate3d(${this.state.overlayContentToTransformValue}px, 0, 0)`;
					break;
			}

			if (this.props.overlayOptions?.animateOnClose?.scaleAmount) {
				const difference = (Number(this.props.overlayOptions?.animateOnClose?.scaleAmount)/100)*-1;
				const scaleAmount = 1 - difference;
				overlayCloseContentToTransform += ` scale(${scaleAmount})`;
			}

			if (this.props.overlayOptions?.animateOnClose?.rotateAmount) {
				overlayCloseContentToTransform += ` rotate(${this.props.overlayOptions?.animateOnClose?.rotateAmount}deg)`;
			}

			let overlayOpenContentFromClipMask = 'none';
			switch(this.props.overlayOptions?.animateOnOpen?.wipeShape) {
				case 'right': 
					overlayOpenContentFromClipMask = 'inset(0 0 0 100%)'
					break;
				case 'left':
					overlayOpenContentFromClipMask = 'inset(0 100% 0 0)'
					break;
				case 'top':
					overlayOpenContentFromClipMask = 'inset(0 0 100% 0)'
					break;
				case 'bottom':
					overlayOpenContentFromClipMask = 'inset(100% 0 0 0)'
					break;
				case 'circle': 
					overlayOpenContentFromClipMask = 'circle(0%)'
					break;
				case 'diamond':
					overlayOpenContentFromClipMask = 'polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%)'
					break;
				case 'square':
					overlayOpenContentFromClipMask = 'inset(100% 100% 100% 100%)'
					break;
			}

			let overlayCloseContentFromClipMask = 'none';
			switch(this.props.overlayOptions?.animateOnClose?.wipeShape) {
				case 'right':
				case 'left':
				case 'top':
				case 'bottom':
					overlayCloseContentFromClipMask = 'inset(0 0 0 0)'
					break;
				case 'circle':
					overlayCloseContentFromClipMask = 'circle(125%)'
					break;
				case 'diamond':
					overlayCloseContentFromClipMask = 'polygon(-50% 50%, 50% -50%, 150% 50%, 50% 150%)'
					break;
				case 'square':
					overlayCloseContentFromClipMask = 'inset(0 0 0 0)'
					break;
			}

			let overlayOpenContentToClipMask = 'none';
			switch(this.props.overlayOptions?.animateOnOpen?.wipeShape) {
				case 'right':
				case 'left':
				case 'top':
				case 'bottom':
					overlayOpenContentToClipMask = 'inset(0 0 0 0)'
					break;
				case 'circle':
					overlayOpenContentToClipMask = 'circle(125%)'
					break;
				case 'diamond':
					overlayOpenContentToClipMask = 'polygon(-50% 50%, 50% -50%, 150% 50%, 50% 150%)'
					break;
				case 'square':
					overlayOpenContentToClipMask = 'inset(0 0 0 0)'
					break;
			}

			let overlayCloseContentToClipMask = 'none';
			switch(this.props.overlayOptions?.animateOnClose?.wipeShape) {
				case 'left': 
					overlayCloseContentToClipMask = 'inset(0 0 0 100%)'
					break;
				case 'right':
					overlayCloseContentToClipMask = 'inset(0 100% 0 0)'
					break;
				case 'bottom':
					overlayCloseContentToClipMask = 'inset(0 0 100% 0)'
					break;
				case 'top':
					overlayCloseContentToClipMask = 'inset(100% 0 0 0)'
					break;
				case 'circle': 
					overlayCloseContentToClipMask = 'circle(0%)'
					break;
				case 'diamond':
					overlayCloseContentToClipMask = 'polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%)'
					break;
				case 'square':
					overlayCloseContentToClipMask = 'inset(100% 100% 100% 100%)'
					break;
			}

			let overlayOpenEasing = 'linear';
			switch(this.props.overlayOptions?.animateOnOpen?.easing) {
				case 'ease-in':
					overlayOpenEasing = 'ease-in';
					break;
				case 'ease-out':
					overlayOpenEasing = 'ease-out';
					break;
				case 'ease-in-out':
					overlayOpenEasing = 'ease-in-out';
					break;
				case 'bounce':
					overlayOpenEasing = 'cubic-bezier(.47,1.64,.41,.8)';
					break;
				case 'elastic':
					overlayOpenEasing = 'cubic-bezier(.2,.69,.41,1.12)';
					break;
				case 'hesitate':
					overlayOpenEasing = 'cubic-bezier(0,.8,1,0)';
					break;
				case 'linear':
				default:
					overlayOpenEasing = 'linear';
					break;
			}

			let overlayCloseEasing = 'linear';
			switch(this.props.overlayOptions?.animateOnClose?.easing) {
				case 'ease-in':
					overlayCloseEasing = 'ease-in';
					break;
				case 'ease-out':
					overlayCloseEasing = 'ease-out';
					break;
				case 'ease-in-out':
					overlayCloseEasing = 'ease-in-out';
					break;
				case 'bounce':
					overlayCloseEasing = 'cubic-bezier(.47,1.64,.41,.8)';
					break;
				case 'elastic':
					overlayCloseEasing = 'cubic-bezier(.2,.69,.41,1.12)';
					break;
				case 'hesitate':
					overlayCloseEasing = 'cubic-bezier(0,.8,1,0)';
					break;
				case 'linear':
				default:
					overlayCloseEasing = 'linear';
					break;
			}
			
			let overlayZIndex = 999;

			overlayRelatedCSS = {
				'--overlay-open-duration': this.props.overlayOptions?.animateOnOpen?.speed ? `${this.props.overlayOptions?.animateOnOpen?.speed}s` : '0s',
				'--overlay-open-from-opacity': this.props.overlayOptions?.animateOnOpen?.fade ? 0 : 1,
				'--overlay-open-from-background-color': this.props.overlayOptions?.animateOnOpen?.fade ? 'inherit' : 'transparent',
				'--overlay-open-content-from-transform': overlayOpenContentFromTransform,
				'--overlay-open-content-from-clip-mask': overlayOpenContentFromClipMask,
				'--overlay-open-content-to-clip-mask': overlayOpenContentToClipMask,
				'--overlay-close-duration': this.props.overlayOptions?.animateOnClose?.speed ? `${this.props.overlayOptions?.animateOnClose?.speed}s` : '0s',
				'--overlay-close-to-opacity': this.props.overlayOptions?.animateOnClose?.fade ? 0 : 1,
				'--overlay-close-to-background-color': this.props.overlayOptions?.animateOnClose?.fade ? 'inherit' : 'transparent',
				'--overlay-close-content-to-transform': overlayCloseContentToTransform,
				'--overlay-close-content-from-clip-mask': overlayCloseContentFromClipMask,
				'--overlay-close-content-to-clip-mask': overlayCloseContentToClipMask,
				'--overlay-open-easing': overlayOpenEasing,
				'--overlay-close-easing': overlayCloseEasing,
				opacity: this.state.overlayContentFromTransformValue !== null ? undefined : 0,
				zIndex: overlayZIndex,
			}

		}

		if(this.state.fullyRender) {

			// get all of the padding and margins together that we've gathered and use them to set a 'max fit height' for media items
			let pad = this.props.contentPad.top +
				this.state.pageLayoutPad.top +
				this.state.pageContentPad.top +
				this.state.pageContentPad.bottom +
				this.state.pageLayoutPad.bottom +
				this.props.contentPad.bottom;

			if( pinRelatedCSS ){

				if (pinRelatedCSS.paddingTop ){
					pad += pinRelatedCSS.paddingTop;
				}

				if (pinRelatedCSS.paddingBottom ){
					pad += pinRelatedCSS.paddingBottom;
				}
			}

			let maxFitHeight = Math.max(10, this.state.windowHeight - pad);
			let pageContent = null;

			if(this.props.access_level === 'password') {

				pageContent = <Password target={this.props.pid} onSucces={() => {
					this.props.fetchContent(this.props.pid, {
						force: true
					});
				}}/>

			} else {
				if(this.hasOwnProperty('lockedContent')) {
					// do not attempt to render page content twice. This is extremely important
					// for the admin so we can assume control over the page content. Bad things 
					// will happen if both preact and the admin modify this
					pageContent = this.lockedContent;
				} else {
					pageContent = this.lockedContent = this.props.content;
				}
			}

			this.maxFitHeight = maxFitHeight;

			const bodyCopyJSX = <bodycopy
				style={{
					'--fit-height': maxFitHeight + 'px',
					'--resize-parent-width': this.state.resizeParentWidth,
				}}
				ref={this.bodycopyRef}
				dangerouslySetInnerHTML={ typeof pageContent === "string" ? {__html: pageContent} : undefined }
			>
				{isValidElement(pageContent) ? pageContent : null}
			</bodycopy>

			pageContentJSX = <PageInfoContextProvider
				pageRef={this.pageRef}
				value={this.getPageInfoContextValue()}
			>
				<div className="page-layout" ref={this.pageLayoutRef}>
					<div className={`page-content${this.props?.local_css?.includes('--page-content-backdrop-filter') ? ' has-content-backdrop-filter' : ''}`} ref={this.pageContentRef} style={layeringRelatedCSS}>
						{this.props.adminMode && this.props.Editor_PageEditorOutlines !== false && 
							<EditPageButton
								bodycopyRef={this.bodycopyRef}
								pageTitle={this.props.title}
								pageContent={this.props.content}
								pageIsPin={this.isPinned}
								isEditingPage={this.props.isEditingPage}
								editPage={this.editPage}
							/>
						}
						<UsesWatcher
							pageInfo={this.getPageInfoContextValue()}
							adminMode={this.props.adminMode}
							bodycopyRef={this.bodycopyRef}
						>
							<CustomElementHost portalHost={bodyCopyJSX} />
							{bodyCopyJSX}
						</UsesWatcher>
					</div>
				</div>

			</PageInfoContextProvider>			

		}

		/*
		we can't conditionally wrap the content with ScrollContextProvider because it would cause
		re-rendering of the page when toggling pins. so instead it renders a 'pass-through' context
		unless we have a scrolling element that isn't the window (overlay, fixed pin)
		*/
		let passthroughScrollContext = true;
		if ( pinOptions?.fixed || this.props.isOverlay){
			passthroughScrollContext = false;
		}

		if(this.state.renderSparseHeightDiv) {

			pinRelatedCSS = pinRelatedCSS || {};

			// force the height of the sparse container and ensure it never grows
			// beyond its bounds while the sparse height is enforced
			pinRelatedCSS.height = (this.state.lastPageContainerHeight) + 'px';
			pinRelatedCSS.overflow = 'hidden';

		}

		pageJSX = <div 
			id={this.props.pid}
			page-url={this.props.purl?.toLowerCase()}
			className={`page${pinClassList !== null ? pinClassList : ''}${this.props.stackedPage? ' stacked-page':''}${this.props?.local_css?.includes('--page-backdrop-filter') ? ' has-backdrop-filter' : ''}`}
			ref={this.pageRef}
			style={{
				...pinRelatedCSS,
			}}
			editing={this.props.isEditingPage}
		> 
			{hasBackdrop && <Backdrop id={this.props.pid} settings={this.props.backdrops} />}
			<a id={this.props.purl}></a>
			<ScrollContextProvider passthrough={passthroughScrollContext} scrollingElement={this.props.isOverlay? this.overlayContentRef : this.pageContentRef}>
				{this.state.renderSparseHeightDiv && <div style={{height: this.state.lastPageContainerHeight + 'px'}}></div>}
				{pageContentJSX}
			</ScrollContextProvider>

			{/* the inline style tag for local CSS. Don't comment this out as the admin requires it for local style previewing */}
			<style ref={this.localStyleRef}>{this.props.local_css}</style>
			<style id={`mobile-offset-styles-${this.props.pid}`}>{this.state.mobileOffsetsString}</style>
		</div>

		return (
			this.props.isOverlay === true ? <div
				ref={this.overlayContentRef}
				className={'overlay-content '+overlayRelatedClasses}
				style={overlayRelatedCSS}
			>{pageJSX}</div> : pageJSX
		)

	}

	componentDidUpdate = (prevProps, prevState) => {

		this.bindObservers();

		this.setPinProperties(prevProps);

		if(this.props.isOverlay === true) {
			this.setOverlayScrollability();
			if (
				this.state.overlayContentFromTransformValue !== null &&
				this.overlayContentRef?.current?.classList.contains('overlay-open') === false &&
				this.props.overlayOptions?.animateOnOpen !== false &&
				this.props.overlayOptions?.animateOnOpen?.speed !== 0
			) {
				this.overlayContentRef?.current?.classList.add('overlay-open');
				this.overlayContentRef?.current?.classList.add('overlay-animating');
			}
		}


		if(
			prevProps.content === undefined && 
			this.props.content !== prevProps.content
		) {
			// rendered content after it not being loaded before
			// like when unlocking it with a password. Make sure we 
			// fetch product links and set the correct active links
			this.checkAndSetActiveLinks();
			this.fetchCommerceProductsByLink();
		}

		if(this.state.fullyRender === true && prevState.fullyRender === false) {

			// the page is fully rendered but was sparse before
			this.onFullComponentRender();

		} else if(this.state.fullyRender === false && prevState.fullyRender === true) {

			// the page is sparse but was fully rendered bfore
			this.onSparseComponentRender();

		}

		if (
			// check active links as routing occurs
			prevProps.activePURL !== this.props.activePURL
			|| prevProps.activePID !== this.props.activePID
			// check when going in and out of preview
			|| prevProps.adminMode !== this.props.adminMode
		) {
			this.checkAndSetActiveLinks();
			this.temporarySafariRenderingFix();
		}

		if( this.props.local_css !== prevProps.local_css ) {
			this.setMobileOffsets();
		}

		if( this.props.globalStylesheet !== prevProps.globalStylesheet ) {
			this.setMobileOffsets();
		}

		if(prevProps.isMobile !== this.props.isMobile) {
			this.setMobileOffsets();
		}

		// We navigated to another hash, and the page has moved 
		// to above or below the new hash. Make sure we correct
		// the height of the page if needed
		if(this.props.position !== prevProps.position) {
			this.isAboveViewport = this.props.position === 'above';
			this.onResize(this.pageRef.current.getBoundingClientRect())
		}

	}

	shouldComponentUpdate(nextProps, nextState, nextContext) {

		// Don't attempt to render if no props/state/context has changed
		if(
			this.props === nextProps 
			&& this.state === nextState
			&& this.context === nextContext
		) {
			return false;
		}

		if(
			// we're using locked content
			this.hasOwnProperty('lockedContent')
			// and content prop changed
			&& this.props.content !== nextProps.content
			// but everything else is still equal
			&& shallowEqual({...this.props, content: null}, {...nextProps, content: null})
			&& this.state === nextState
			&& this.context === nextContext
		) {
			// no need to render
			return false;
		}

		return true;

	}

	setPinProperties = (prevProps) => {

		if(!this.props.isPin) {
			return;
		}

		if(!this.pageRef.current) {
			return;
		}

		// scroll bottom pins into view when making changes via the editor
		if (this.props.adminMode && _.isEqual(this.props.pin_options, prevProps.pin_options) === false) {

			this.pageRef.current.scrollIntoView();

			if (this.props.pin_options.position === 'bottom') {
				
				let adjustPair = this.context.adjustPairs[this.props.pid];

				if (adjustPair && adjustPair.adjusts) {
					let adjustedEl = document.body.querySelector("[id='"+adjustPair.adjusts[0]+"']");	
					requestAnimationFrame(()=>{
						adjustedEl.scrollIntoView({block: "end"})
					});
				} 
			}

		}

		if (this.props.pin_options.fixed === true) {
			this.setFixedPinScrollability();
		}

		// add an 'accepts-pointer-events' class for pages / page content containers with a backdrop or background color
		if (this.props.pin_options.overlay === true && this.props.local_css !== '') {
			
			// debounce this as during window resizing this causes a lot of expensive repaints
			this.checkBackgroundColor();

		}
	}

	checkBackgroundColor = _.debounce(() => {

		if(!this.pageRef.current) {
			return;
		}

		const hasBackdrop = this.props.backdrops?.activeBackdrop && this.props.backdrops?.activeBackdrop != 'none';
			
		if (!hasBackdrop && this.hasNoBackgroundColor(this.pageRef.current)) {
			this.pageRef.current.classList.remove('accepts-pointer-events')
		} else {
			this.pageRef.current.classList.add('accepts-pointer-events')
		}

		let pageContentContainer = this.pageRef.current?.querySelector('.page-content');
		
		if (this.hasNoBackgroundColor(pageContentContainer)) {
			pageContentContainer.classList.remove('accepts-pointer-events')
		} else {
			pageContentContainer.classList.add('accepts-pointer-events')
		}

	}, 100)

	hasNoBackgroundColor = (element) => {
		if (element == undefined) {return false;}

		let styles = window.getComputedStyle(element, null),
			bg_color = styles.getPropertyValue("background-color"),
			opacity = (bg_color.match('rgba')) ? bg_color.replace(/^.*,(.+)\)/,'$1') : null;

		return bg_color == undefined || bg_color == 'transparent' || bg_color == 'initial' || opacity == 0;
	}

	setFixedPinScrollability = () => {

		if(!this.pageRef.current) {
			return;
		}

		let pageContentContainer = this.pageContentRef.current;
		let pageBodycopyContainer = this.bodycopyRef.current;
		if (
			pageContentContainer 
			&& pageBodycopyContainer 
			&& pageBodycopyContainer.clientHeight > pageContentContainer.clientHeight
		) {
			this.pageRef.current.classList.add('allow-scroll')
		} else {
			this.pageRef.current.classList.remove('allow-scroll')
		}

	}

	setOverlayScrollability = () => {

		if(!this.overlayContentRef.current) {
			return;
		}

		// allow a little cheat pixel so we don't get scrollbars for half-pixels
		if (
			this.overlayContentRef.current.scrollHeight > (this.overlayContentRef.current.offsetHeight+1) 
		) {
			this.overlayContentRef.current.classList.add('overlay-allow-scroll');		
		} else {
			this.overlayContentRef.current.classList.remove('overlay-allow-scroll');		
		}
	}

	checkAndSetActiveLinks = (e) => {

		if(!this.pageRef.current) {
			return;
		}

		let activeFilter = e?.detail?.filter
		if( activeFilter?.startsWith('tag:') ){
			activeFilter = activeFilter.replace('tag:', '').trim();
		} else if( activeFilter) {
			activeFilter = 'all';
		} else {
			activeFilter = null;
		}

		const isInAdmin = this.props.inAdminFrame && this.props.adminMode === true;

		// If editing in the admin, do not set active links, or they will be stored in CRDT
		// isInAdmin prevents the active class from being added while in the admin.
		if (this.props.isEditingPage && !isInAdmin) {
			return;
		}

		// get all 'rel="history"' links and apply 'active' when matching the active PURL
		const links = this.pageRef.current.querySelectorAll('[rel="history"], [rel="filter-index"], [rel="home-page"]');
		
		// match homepage PURL and if that doesn't match, check if we rendered the root set (for feed sites)
		const isOnHomePage = this.props.activePURL === this.props.homepagePURL || this.props.activePID === 'root';

		_.each(links, (link) => {

			const purl = link.getAttribute("href")?.toLowerCase();
			const isOnActivePage = purl === this.props.activePURL || this.props.activePurlParents?.includes(purl);

			// regular 'href' history links other than ones to the home page
			if (!isInAdmin && isOnActivePage && this.props.homepagePURL !== purl) {
				link.classList.add('active');
			} else {
				link.classList.remove('active');
			}

			// home page links
			if (!isInAdmin && this.props.enableHomeLinkActiveStates
				//if is an href to the home page, or a rel="home-page" 
				&& (
					this.props.homepagePURL === purl 
					|| link.getAttribute("rel") === 'home-page'
				)
			) {
				if (isOnHomePage) {
					link.classList.add('active');
				} else {
					link.classList.remove('active');
				}
			}
		})
		
	}

	bindObservers = (willUnmount=false)=>{

		if( this.pageRef.current !== this.lastPageRef.current || willUnmount ){
			
			if( this.lastPageRef.current){
				pageMap.delete(this.lastPageRef.current);
				resizeObserver.unobserve(this.lastPageRef.current);
				aboveViewportObserver.unobserve(this.lastPageRef.current);
				viewportBoundaryObserver.unobserve(this.lastPageRef.current);
			}

			if( this.pageRef.current && !willUnmount){
				pageMap.set(this.pageRef.current, this);
				resizeObserver.observe(this.pageRef.current);
				aboveViewportObserver.observe(this.pageRef.current);
				viewportBoundaryObserver.observe(this.pageRef.current);
			}

			this.lastPageRef.current = this.pageRef.current;

		}

		if( this.pageLayoutRef.current !== this.lastPageLayoutRef.current || willUnmount ){

			if( this.lastPageLayoutRef.current ){
				pageLayoutMap.delete(this.lastPageLayoutRef.current);
				resizeObserver.unobserve(this.lastPageLayoutRef.current);
			}

			if( this.pageLayoutRef.current && !willUnmount){
				pageLayoutMap.set(this.pageLayoutRef.current, this);
				resizeObserver.observe(this.pageLayoutRef.current);
			}
			this.lastPageLayoutRef.current = this.pageLayoutRef.current;
		}

		if( this.pageContentRef.current !== this.lastPageContentRef.current || willUnmount ){

			if( this.lastPageContentRef.current){
				pageContentMap.delete(this.lastPageContentRef.current);
				resizeObserver.unobserve(this.lastPageContentRef.current);
			}
			if( this.pageContentRef.current && !willUnmount ){
				pageContentMap.set(this.pageContentRef.current, this);
				resizeObserver.observe(this.pageContentRef.current);
			}
			this.lastPageContentRef.current = this.pageContentRef.current;
		}

		if( this.bodycopyRef.current !== this.lastBodycopyRef.current || willUnmount ){

			if( this.lastBodycopyRef.current){
				this.lastBodycopyRef.current.removeEventListener('filter-thumbnail-index', this.checkAndSetActiveLinks);
				bodycopyMap.delete(this.lastBodycopyRef.current);
				resizeObserver.unobserve(this.lastBodycopyRef.current);
			}

			if( this.bodycopyRef.current && !willUnmount){
				bodycopyMap.set(this.bodycopyRef.current, this);
				resizeObserver.observe(this.bodycopyRef.current);
				this.bodycopyRef.current.addEventListener('filter-thumbnail-index', this.checkAndSetActiveLinks);
			}

			this.lastBodycopyRef.current = this.bodycopyRef.current;

		}

	}

	componentDidMount() {

		// set initial above viewport value. If the page's position is above the
		// viewport we'll have to adjust the scroll position to accomodate it
		this.isAboveViewport = this.props.position === 'above';

		// immediately handle page size and position
		this.onResize(this.pageRef.current.getBoundingClientRect())

		this.onWindowResize();
		windowInfo.on('window-resize', this.onWindowResize)
		
		if(this.state.fullyRender === true) {
			this.onFullComponentRender();
		}

		// let pin context know about our element instantly
		this.context.onPageMount(this.pageRef.current);

		if(this.pageRef.current) {
			
			// find initial embeds
			embeds.findEmbedsIn(this.pageRef.current);

			// observe new embeds
			if(embedObserver) {
				embedObserver.observe(this.pageRef.current, { childList: true, subtree: true });
			}
			
			// reload embeds after dom binding was attached
			this.pageRef.current.addEventListener('dom-binding-initialized', () => {
				embeds.findEmbedsIn(this.pageRef.current)
			});

			
			if( this.props.isOverlay){
				window.setTimeout(this.setOverlayContentTransformValues, 100);
				this.calculateOverlayPassthroughClasses();
				this.pageRef.current.addEventListener('animationend', this.calculateOverlayPassthroughClasses);
			}
			

			this.temporarySafariRenderingFix();

		}

	}

	calculateOverlayPassthroughClasses = ()=>{


		if( !this.pageRef.current || !this.pageContentRef.current){
			return
		}

		const pageComputedStyle = window.getComputedStyle(this.pageRef.current, null)
		const pageContentComputedStyle = window.getComputedStyle(this.pageContentRef.current, null);
		const pageBackgroundColor = pageComputedStyle.getPropertyValue('background-color');
		const pageContentBackgroundColor = pageContentComputedStyle.getPropertyValue('background-color');

		const isPassthrough = this.props.isOverlay && (pageBackgroundColor === 'transparent' || pageBackgroundColor === 'initial' || pageBackgroundColor === 'rgba(0, 0, 0, 0)');
		const isContentPassthrough = this.props.isOverlay && (pageContentBackgroundColor === 'transparent' || pageContentBackgroundColor === 'initial' || pageContentBackgroundColor === 'rgba(0, 0, 0, 0)') && pageBackgroundColor.startsWith('rgba(');

		if (isPassthrough) {
			this.overlayContentRef.current.classList.add('is-passthrough-overlay');
		} else {
			this.overlayContentRef.current.classList.remove('is-passthrough-overlay');
		}
		if (isContentPassthrough) {
			this.overlayContentRef.current.classList.add('is-content-passthrough-overlay');
		} else {
			this.overlayContentRef.current.classList.remove('is-content-passthrough-overlay');
		}
	}

	temporarySafariRenderingFix = () => {
		if (this.props.adminMode) return;
		setTimeout(()=>{
			if (!helpers.isServer && helpers.isSafari() && this.props?.pin_options?.position === 'bottom') {
				this.pageRef?.current?.classList.add('loading');
				setTimeout(()=>{
					this.pageRef?.current?.classList.remove('loading');
				}, 1200)
			}
		}, 10)
	}

	onFullComponentRender() {
		
		if(this.pageLayoutRef.current) {

			function markNodeAsSaveable(node) {
				node.childNodes.forEach(node => node.setSaveable(true));
				for (const child of node.childNodes) {
					markNodeAsSaveable(child);
				}
			}

			markNodeAsSaveable(this.pageLayoutRef.current);

		}

		if(this.pageRef.current) {

			// handle scripts
			this.initializeScriptTags();
			this.props.pageMounted(this.pageRef.current).then(() => {

				// wait till the page mount call is complete. If we have a DOM binding for this 
				// page it'll sync the page to what's in the CRDT, overwriting any differences
				// that these functions might have caused
				this.checkAndSetActiveLinks();
				this.fetchCommerceProductsByLink();

			})
		}

		if (this.props.isPin && this.props.pin_options?.fixed === true) {
			this.setFixedPinScrollability();
		}

		if (this.props.isOverlay === true) {
			this.setOverlayScrollability();
		}

		if(this.props.isMobile) {
			this.setMobileOffsets();
		}

		this.bindObservers();

	}

	componentWillUnmount() {

		if( this.pageRef.current){
			this.pageRef.current.removeEventListener('animationend', this.calculateOverlayPassthroughClasses);
		}

		windowInfo.off('window-resize', this.onWindowResize);

		pageMap.delete(this.pageRef.current);
		
		// remove observers and mark page as unmounted
		this.onSparseComponentRender(true);

		// manually resize to 0 height
		this.onResize({ height: 0 });

		// let pin context know instantly
		this.context.onPageUnmount(this.pageRef.current);

	}

	onSparseComponentRender(willUnmount=false) {

		this.bindObservers(willUnmount);

		if(this.pageRef.current) {
			this.props.pageUnMounted(this.pageRef.current);
		}

	}

	fetchCommerceProductsByLink = () => {

		if(!this.pageRef.current) {
			return;
		}

		if (!this.props.has_shop) return;

		let links = this.pageRef.current.querySelectorAll('[rel="add-to-cart"], [rel="add_to_cart"]');
		let linkedProductIDs = [];

		_.each(links, (link) => {
			let productID = link.getAttribute("data-product");

			if (productID) {
				linkedProductIDs.push(productID);
			}
		})

		this.props.fetchCommerceProducts({
			idArray: linkedProductIDs,
			onlyNew: true
		})
	}

	initializeScriptTags = () => {

		let errorOccured = false;
		const onError = function(e){
			errorOccured = true;
		}

		window.addEventListener('error', onError, false);

		_.each(this.bodycopyRef.current?.querySelectorAll('script'), script => {

			// these are non-executable scripts - pass over them
			if( script.getAttribute('type') === 'text/thumbnail-metadata'){
				return;
			}

			// clear any unrelated errors
			errorOccured = false;

			// need to create a new script node for it to be executed
			const newScriptNode = document.createElement('script');

			// copy over attributes
			[...script.attributes].forEach( attr => { 
				newScriptNode.setAttribute(attr.nodeName, attr.nodeValue) 
			})

			// set script body
			newScriptNode.innerHTML = script.textContent;

			// insert it
			script.replaceWith(newScriptNode);

			if(errorOccured) {
				
				// reset
				errorOccured = false;

				// replace script with a commented out version
				newScriptNode.parentNode.insertBefore(document.createComment(newScriptNode.outerHTML), newScriptNode);
				newScriptNode.remove();

				if(this.props.adminMode) {
					// alert('One or more scripts on this page are broken and were disabled.');
					this.props.store.dispatch({
						type: 'UPDATE_FRONTEND_STATE', 
						payload: {
							alertModal: { 
								message: 'One or more scripts on this page are broken and were disabled.',
								type: 'notice'
							}
						}
					});
				}

			}

		});

		window.removeEventListener('error', onError);

	}

}

Page.contextType = PinManagerContext;

function mapDispatchToProps(dispatch) {
	
	return bindActionCreators({
		pageMounted         : actions.pageMounted,
		pageUnMounted       : actions.pageUnMounted,
		updateFrontendState : actions.updateFrontendState,
		fetchContent        : actions.fetchContent,
		fetchCommerceProducts: actions.fetchCommerceProducts,
	}, dispatch);

}

const getParentPurls = memoizeWeak((parentSetList, setsById) => {

	// get parent pids and map them to purls
	return parentSetList.map(pid => setsById[pid]?.purl)
		// remove any invalid data
		.filter(purl => purl && typeof purl === "string")
		// convert purls to lowercase
		.map(purl => purl.toLowerCase());

});


export default withStore(connect(
	(state, ownProps) => {

		const page = selectors.getContentById(state, ownProps.id);

		const activeContent = (state.pages.byId[state.frontendState.activePID] || state.sets.byId[state.frontendState.activePID]);
		let activePURL;
		let activePurlParents;

		if(activeContent) {
			activePURL = activeContent.purl?.toLowerCase();
			activePurlParents = getParentPurls(selectors.getParentSetList(state, activeContent.id), state.sets.byId)
		}

		if(!helpers.isServer && !activePURL) {
			activePURL = window.location.pathname.replace(/^\//, '').toLowerCase();
		}
		
		return {
			inAdminFrame				: state.frontendState.inAdminFrame,
			isMobile 					: state.frontendState.isMobile,
			adminMode					: state.frontendState.adminMode,
			isEditingPage 				: page?.id && state.frontendState.PIDBeingEdited === page?.id,
			hasPage 					: page !== undefined,
			homepagePURL				: selectors.getHomepagePurl(state),
			pid							: page?.id,
			purl						: page?.purl,
			isPin						: page?.pin,
			title						: page?.title,
			backdrops					: page?.backdrops,
			content						: page?.content,
			access_level				: page?.access_level,
			local_css					: page?.local_css,
			pin_options					: page?.pin_options,
			PIDBeingEdited 				: state.frontendState.PIDBeingEdited,
			globalStylesheet			: state.css?.stylesheet,
			enableHomeLinkActiveStates	: state.siteDesign?.site?.enableHomeLinkActiveStates,
			activePID					: state.frontendState.activePID,
			activePURL					: activePURL,
			activePurlParents			: activePurlParents,
			pageDepth					: selectors.getParentSetList(state, ownProps.id).length -1,
			has_shop					: state.site.shop_id !== null,
			Editor_PageEditorOutlines	: state.adminState?.localStorage?.Editor_PageEditorOutlines
		}
	},
	mapDispatchToProps
)(
	Page
));
